import React from "react";
import "./NotFound.css";
import { TailSpin } from "react-loader-spinner";

export default function PageNotFound(props) {
  return (
    <div className="container">
      <span className="page-title">
        {props.pageTitle ?? "404 - Page Not Found"}
      </span>
      <span className="page-body">
        {props.pageBody ??
          "The URL entered does not exist on this website. Please use the calendar on portal.brightlighttutorials.com to get valid links for classes."}
      </span>
    </div>
  );
}
