import { getApp, getApps, initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
// import { Result } from "true-myth";

// TODO: move environment variables to .env file

const initializeFirebaseApp = () => {
  if (getApps().length === 0) {
    // initialise the firebase app
    initializeApp({
      apiKey: "AIzaSyDzVM4OJfWSMjJzonDnc1umaOhT72unjRU",
      authDomain: "brightlight-academy.firebaseapp.com",
      projectId: "brightlight-academy",
      storageBucket: "brightlight-academy.appspot.com",
      messagingSenderId: "596163381063",
      appId: "1:596163381063:web:9f45a0809f40c441872e62",
      measurementId: "G-ZPLXY16081",

      //         NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN=brightlight-academy.firebaseapp.com
      // NEXT_PUBLIC_FIREBASE_PROJECT_ID=brightlight-academy
      // NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET=brightlight-academy.appspot.com
      // NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID=596163381063
      // NEXT_PUBLIC_FIREBASE_APP_ID=1:596163381063:web:9f45a0809f40c441872e62
      // NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID=G-ZPLXY16081
      // NEXT_PUBLIC_FIREBASE_API_KEY=AIzaSyDzVM4OJfWSMjJzonDnc1umaOhT72unjRU
    });
  }
};

// TODO:
// Register auth state change function

class AuthService {
  _auth;
  _userID;

  constructor() {
    const apps = getApps();
    if (apps.length === 0) {
      initializeFirebaseApp();
      this._auth = getAuth(getApp());
    } else {
      this._auth = getAuth(apps[0]);
    }

    this._userID = this._auth.currentUser?.uid;
    this._auth.onAuthStateChanged((user) => this.setUser(user));
  }
  getCurrentUserEmail() {
    if (!this._auth) return undefined;

    return this._auth.currentUser?.email ?? undefined;
  }

  async loginWithGoogle() {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(this._auth, provider);
      this._userID = this._auth.currentUser?.uid;

      return true;
    } catch (error) {
      let message = "";
      let code = "";

      if (error.code === "auth/invalid-email") {
        // Handle invalid email error
        message = "The email/password is incorrect.";
        code = error.code;
      } else if (error.code === "auth/wrong-password") {
        // Handle wrong password error
        message = "The email/password is incorrect.";
        code = error.code;
      } else if (error.code === "auth/user-not-found") {
        // Handle user not found error
        message = "The email/password is incorrect.";
        code = error.code;
      } else if (error.code === "auth/email-already-in-use") {
        // Handle email already in use error
        message = "The email provided is already in use.";
        code = error.code;
      } else if (error.code === "auth/weak-password") {
        // Handle weak password error
        message = "The password provided is too weak.";
        code = error.code;
      } else {
        message = "An unexpected error occured.";
        code = "unhandled_exception";
      }

      return {
        code,
        message,
      };
    }
  }
  async logout() {
    await signOut(this._auth);
  }

  setUser(user) {
    this._userID = user?.uid;
  }

  onAuthStateChangedListener(nextOrObserver, error, completed) {
    return this._auth.onAuthStateChanged;
  }

  getCurrentUserID() {
    return this._userID;
  }
}

export default AuthService;
