import React from "react";
import "./Header.css";

export default function Header() {
  return (
    <>
      <div className="container">
        <p className="header-title">
          BrightLight <span className="title-highlight">Classes</span>{" "}
        </p>
      </div>
    </>
  );
}
