import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./App.css";
import { ZoomMtg } from "@zoom/meetingsdk";
import AuthService from "./auth service/auth_service";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";
import { TailSpin } from "react-loader-spinner";
import PageNotFound from "./NotFound";

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

function App() {
  const authService = new AuthService();
  const { moduleCode, meetingNumber } = useParams();
  const [userID, setuserID] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const signInAndJoinClass = async () => {
    setLoading(true);
    const result = await authService.loginWithGoogle();
    if (typeof result === "boolean") {
      if (result) {
        setuserID(authService.getCurrentUserID());
        await getZoomClassCredentials(authService.getCurrentUserID());
      } else {
        setError(
          "You are not registered on the platform. Please register on portal.brightlighttutorials.com."
        );

        setLoading(false);
      }
    }
  };

  // useEffect(() => {

  //   signInAndJoinClass();
  // }, []);

  const getZoomClassCredentials = async (userID) => {
    const func = httpsCallable(
      getFunctions(getApp()),
      "getZoomClassCredentials"
    );
    const result = await func({ userID, meetingNumber, moduleCode });

    if (result.data["success"] === true) {
      // alert("Succeeded");

      const signature = result.data["signature"];
      const password = result.data["password"];
      const email = result.data["email"];
      const username = result.data["username"];

      await startMeeting(
        signature,
        password,
        email,
        username,
        moduleCode,
        meetingNumber
      );
    } else {
      if (typeof result.data["error"] === "string") {
        setError(result.data["error"]);
      } else {
        setError(
          "Failed to join class. Please use a valid URL from the calendar on portal.brightlighttutorials.com"
        );
      }
      setLoading(false);
    }
  };

  async function startMeeting(signature, password, email, username) {
    // const signature = await generateSignature();
    document.getElementById("zmmtg-root").style.display = "block";
    ZoomMtg.init({
      leaveUrl: `portal.brightlighttutorials.com/portal/${moduleCode}`,
      patchJsMedia: true,
      success: (success) => {
        console.log(success);
        ZoomMtg.join({
          signature: signature,
          sdkKey: "K988n1dxTXSq0dtF8tOfPg",
          meetingNumber: meetingNumber,
          passWord: password,
          userName: username,
          userEmail: email,
          success: (success) => {},
          error: (error) => {
            if (typeof error === "string") {
              setError(error);
            } else {
              setError(
                "Failed to join class. Please use a valid URL from the calendar on portal.brightlighttutorials.com"
              );
            }
            setLoading(false);
          },
        });
      },
      error: (error) => {
        if (typeof error === "string") {
          setError(error);
        } else {
          setError(
            "Failed to join class. Please use a valid URL from the calendar on portal.brightlighttutorials.com"
          );
        }
        setLoading(false);
      },
    });
  }

  return (
    <div className="App">
      <main>
        {error.length === 0 && (
          <p className="join-class-title">{moduleCode} Online Class</p>
        )}

        {!loading && error.length > 0 && (
          <PageNotFound pageTitle="Failed to join class" pageBody={error} />
        )}

        {!loading && (
          <>
            <p className="join-class-caption">
              Request to join the {moduleCode} class using the button below.
            </p>

            <div onClick={signInAndJoinClass} className="join-class-button">
              Join Class {">"}
            </div>
          </>
        )}

        {loading && (
          <>
            <p className="join-class-label">joining class..</p>
            <div className="join-class-spinner">
              <TailSpin
                visible={loading}
                height={50}
                width={50}
                color="#1957b3"
                wrapperClass="loading-spinner"
              />
            </div>
          </>
        )}
      </main>
    </div>
  );
}

export default App;
